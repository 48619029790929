import { AllPlatforms } from './platform';

export const EMAIL_REGEXP = new RegExp(
  /^([_0-9A-Za-z.-]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const YOUTUBE_VIDEO_REGEXP = new RegExp(/^https:\/\/(www\.)?(youtube\.com\/(\w+|live\/[\w\?=]+|shorts\/\w+|watch\?v=\w+)|youtu\.be\/\w+)$/);

export const TIKTOK_VIDEO_REGEXP = new RegExp(
  /^https:\/\/((www\.)?tiktok\.com\/(@[\._0-9A-Za-z]+\/video\/\w+|t\/\w+\/([\?&]\w+=\w+)*)|(vt\.)?tiktok\.com\/\w+(\/)?)$/,
);
export const YOUTUBE_URL_REGEXP = new RegExp(/^https:\/\/www\.youtube\.com\/channel\/UC[-_0-9A-Za-z]{21}[AQgw]$/);

export const PLATFORMS_URL_REGEXP: Record<AllPlatforms, RegExp> = {
  [AllPlatforms.YouTube]: new RegExp(/^(https:\/\/www\.youtube\.com\/channel\/UC[-_0-9A-Za-z]{21}[AQgw])$/),
  [AllPlatforms.TikTok]: new RegExp(/^(https:\/\/www\.tiktok\.com\/@[._0-9A-Za-z]+)(\/.*|\?.*|&.*=.*)*$/),
  [AllPlatforms.Facebook]: new RegExp(
    /^(https:\/\/www\.facebook\.com\/profile\.php\?id=[0-9]+|https:\/\/www\.facebook\.com\/[.0-9A-Za-z]+|)(?!.*id=[.*\D.*]+.*)(\/.*|\?.*|&.*=.*)*$/,
  ),
  [AllPlatforms.Instagram]: new RegExp(/^(https:\/\/www\.instagram\.com\/[._0-9A-Za-z]+\/?)$/),
  [AllPlatforms.Twitch]: new RegExp(/^(https:\/\/www\.twitch\.tv\/[_0-9A-Za-z]+)(\/.*|\?.*|&.*=.*)*$/),
  [AllPlatforms.Twitter]: new RegExp(/^(https:\/\/twitter\.com\/[_0-9A-Za-z]+|https:\/\/x\.com\/[_0-9A-Za-z]+)(\/.*|\?.*|&.*=.*)*$/),
  [AllPlatforms.Others]: new RegExp(/^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/i),
};
