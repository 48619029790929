/*
 * @Date: 2024-01-19 15:58:31
 * @LastEditors: wangkai wangkai04@rd.netease.com
 * @LastEditTime: 2024-02-05 14:32:55
 * @Description: 消息通知网络请求的方法定义
 */
import apiDef from '@/constants/api';
import request from '@/utils/request';
import { NotificationType } from '@/page-components/notification/constants';
import { AllPlatforms } from '@/constants/platform';

export interface PageResult<T> {
  total: number;
  pageNo: number;
  pageCapacity: number;
  resultList: T[];
}

interface NotificationListProps {
  pageCapacity: number;
  pageNo: number;
}
export interface NotificationListResult {
  notificationId: number;
  promotionId: number; //推广系列
  relateId: number;
  kolId: number; //达人
  kolPlatform: AllPlatforms;
  type: NotificationType;
  promotionName: string;
  kolName: string;
  homePageUrl: string;
  avatarUrl: string;
  createTime: string;
  readStatus: boolean; //是否已读
  subjectType: number; // 0-接单邀请相关 1-任务广场相关
  brandName: string; // 品牌名称 任务广场专用
  productName: string; // 产品名称 任务广场专用
}

/**
 * @description: 获取消息通知列表
 * @param {NotificationListProps} params
 * @return {*}
 */
export function getNotificationList(params: NotificationListProps) {
  return request.get<PageResult<NotificationListResult>>(apiDef.getNotificationList, { params });
}

/**
 * @description: 获取未读消息数量
 * @return {*}
 */
export function getUnreadNum() {
  return request.get<number>(apiDef.getUnreadNum);
}

/**
 * @description: 设置消息已读一条
 * @param  data
 * *这个接口的参数要拼接到链接后面
 * @return {*}
 */
export function uploadReadOne(data: Record<string, any>) {
  const params = new URLSearchParams(data);
  const urlWithParams = `${apiDef.uploadReadOne}?${params.toString()}`;
  return request.put(urlWithParams);
}

/**
 * @description: 设置消息全部已读
 * @return {*}
 */
export function uploadReadAll() {
  return request.put(apiDef.uploadReadAll);
}
