/*
 * @Date: 2024-01-19 16:02:15
 * @LastEditors: wangkai wangkai04@rd.netease.com
 * @LastEditTime: 2024-02-28 15:01:31
 * @Description: 消息通知的弹窗（多个页面用到）
 * 若有未读信息，每次重新登录系统时，提示“您有N条未读信息，点击查看详情”，
 * 点击【查看详情】，进入消息通知页面，点击关闭按钮，可关闭提示；
 */

import { useIntl } from 'react-intl';
import styles from './styles/NotificationModal.module.scss';
import { notificationIntlMessgaes } from '@/page-components/notification/constants/intl';
import { Modal } from 'antd';
import { NotificationModalIcon } from '@/constants';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { getNotificationList, NotificationListResult } from '@/services/notification';
import NotificationItem from '@/page-components/notification/NotificationItem';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { changeNotificationModalVisible } from '@/redux/slice/common';
import Empty from '@/page-components/notification/Empty';

const NotificationModal: React.FC = () => {
  const router = useRouter();
  const { formatMessage, locale } = useIntl();
  const [stage, setStage] = useState<'info' | 'list'>('info');
  const [notificationList, setNotificationList] = useState<NotificationListResult[]>([]);

  const { notificationModalVisible, unreadCount } = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const getUnread = async () => {
    const { resultList } = await getNotificationList({
      pageCapacity: 4,
      pageNo: 1,
    });
    setNotificationList(resultList);
  };

  const { firstLogin } = router.query;
  useEffect(() => {
    if (notificationModalVisible) {
      if (firstLogin === 'true') setStage('info');
      else setStage('list');

      getUnread();
    }
  }, [notificationModalVisible, firstLogin, router]);

  const closeModal = useCallback(() => {
    dispatch(changeNotificationModalVisible(false));
  }, [dispatch]);

  // 路由变化时关闭弹窗
  useEffect(() => {
    router.events.on('routeChangeStart', closeModal);
    return () => router.events.off('routeChangeStart', closeModal); // 移除监听
  }, [router, dispatch, closeModal]);

  const handleBtnClick = () => {
    if (stage === 'info') {
      setStage('list');
    } else {
      router.push('/notification');
      closeModal();
    }
  };

  return (
    <div className={styles.localWrapper}>
      <Modal
        wrapClassName={styles.notificationModalWrap}
        className={styles.notificationModal}
        open={notificationModalVisible}
        onCancel={closeModal}
        width={stage === 'list' ? 480 : 348}
        mask={false}
        maskClosable={false}
        getContainer={false}
        footer={
          notificationList.length ? (
            <div className={styles[`${stage}Btn`]} onClick={handleBtnClick}>
              {stage === 'list' ? formatMessage(notificationIntlMessgaes.showAll) : formatMessage(notificationIntlMessgaes.details)}
            </div>
          ) : null
        }
        title={
          stage === 'list' ? (
            <>
              {formatMessage(notificationIntlMessgaes.notifications)}
              <span> ({unreadCount}) </span>
            </>
          ) : null
        }
      >
        {stage === 'list' ? (
          notificationList.length > 0 ? (
            notificationList.map((item) => <NotificationItem key={item.notificationId} notification={item} />)
          ) : (
            <Empty
              customStyle={{
                height: 'fit-content',
              }}
            />
          )
        ) : (
          <div className={styles.infoContent}>
            <div className={styles.icon}>
              <NotificationModalIcon />
            </div>

            <div className={styles.text}>
              <div className={styles.title}>{formatMessage(notificationIntlMessgaes.notifications)}</div>
              <div className={styles.desc}>
                {formatMessage(notificationIntlMessgaes.unreadCount, {
                  count: () => (
                    <span className={styles.num} style={locale === 'en-US' ? { float: 'left' } : {}}>
                      {` ${unreadCount <= 99 ? unreadCount : '99+'} `}
                    </span>
                  ),
                })}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default NotificationModal;
